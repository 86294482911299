// JavaScript Document

// Scripts written by Jordan Bearce @ Webilnx, Inc.

import "lazysizes";
import "lazysizes/plugins/native-loading/ls.native-loading";

document.addEventListener("lazybeforeunveil", (e) => {
    const IMG = e.target.getAttribute("data-bg");

    if (IMG) {
        e.target.style.backgroundImage = `url('${IMG}')`;
    }
});
